class Home {
	constructor () {
		this.loader = new Loader();
		this.loader.load(() => {
			setTimeout(() => {
				this.animation = new Animation();
				this.animation.intro();
			}, 300);
		});
	}
}

class Loader {
	constructor () {
		this.view = $('body');
		this.view.illust = $('.illust');
	}
	load (callback) {
		this.callback = callback;
		var imgs = [];
		var svgs = [];

		this.view.illust.find('*').each((i, item) => {
			if($(item).css('backgroundImage').match(/url\(("|')?(.+?)("|')?\)/)) {
				imgs.push(RegExp.$2);
			}
			if($(item).data('svg')) {
				svgs.push($(item));
			}
		})
		this.loading.count = imgs.length + svgs.length;

		imgs.forEach((item) => {
			$('<img>').on('load', () => {
				this.loading();
			}).attr({ src : item })
		});
		svgs.forEach((item) => {
			$.ajax(item.data('svg')).done((svg) => {
				var importedSVGRootElement = document.importNode(svg.documentElement,true);
				item.append(importedSVGRootElement);
				this.loading();
			});
		});
	}
	loading () {
		if(--this.loading.count == 0) {
			this.callback();
		}
	}
}

class Animation {
	constructor () {
		this.view = $('body');

		this.view.illust = $('.illust');
		this.view.illust.svg = this.view.illust.find('svg');
		this.view.illust.stream = $('.stream');
		this.view.illust.stream.river = this.view.illust.stream.find('.river');
		this.view.illust.stream.h = 0;

		this.setup();
	}

	setup () {
		this.view.illust.svg.each((i, svg) => {
			var W = parseInt($(svg).attr('width'));
			var H = parseInt($(svg).attr('height'));
			var r = H / 2;
			var R = Math.pow(r, 2)

			$(svg).find('image').each((j, item) => {
				var transform = $(item).attr('transform');
				var w = parseInt($(item).width());
				var h = parseInt($(item).height());
				var x = parseInt($(item).attr('x') || 0);
				var y = parseInt($(item).attr('y') || 0);
	
				if(transform.match(/translate\((.*?)\s+(.*?)\)/)) {
					x = x + parseInt(RegExp.$1);
					y = y + parseInt(RegExp.$2);
				}
				var X = x + w / 2;
				var Y = y + h / 2;
				
				$(item).attr({ transform : '', x : x, y : y }).css({
					transformOrigin : `${X}px ${Y}px`,
				});
				if(Math.pow(X - W / 2, 2) + Math.pow(Y - H / 2, 2) < R) {
					$(item).addClass('senior')
				}
			});
		});
		this.scroll();
		common.window.resize(() => { this.resize() } );
		common.window.scroll(() => { this.scroll() } );
	}

	intro (delay) {
		setTimeout(() => {
			this.view.addClass('intro');

			setTimeout(() => {
			}, 5000);
		}, delay);
	}

	resize () {
		this.scroll();
	}

	scroll () {
		clearTimeout(this.view.illust.stream.timer);

		this.view.illust.stream.timer = setTimeout(() => {
			var h = common.window.y + common.window.h;

			if(h > this.view.illust.stream.h) {
				this.view.illust.stream.height(h);
				this.view.illust.stream.h = h;
			}
			if(h > this.view.illust.stream.river.offset().top + this.view.illust.stream.river.height()) {
				this.view.addClass('finale');
			}
		}, 100);
	}
}

window.home = new Home();